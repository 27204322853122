<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mb-2 pb-2">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn @click="addComplaint">Add complaint</v-btn>
          </v-card-title>
        </v-card>
        <v-card>
          <v-card-text>
            <v-data-table
              id="list-table"
              ref="datatable"
              :headers="headers"
              :items="complaints"
              :search="search"
              item-key="index"
              class="mr-2"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td>{{ props.item.fullName }}</td>
                  <td>{{ props.item.ndisNumber}}</td>
                  <td>{{ moment(props.item.createdOn).format("DD-MM-YYYY")}}</td>
                  <td>{{ props.item.nameOfComplaintAgainst}}</td>
                  <td class="justify-center layout px-0">
                    <v-icon small class="mr-2" @click="editComplaint(props.item)">mdi-pencil</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
    <br />
    <br />
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as complaintService from "../../services/complaintService";
import moment from "moment";
Vue.use(VeeValidate);

export default {
  data: () => ({
    search: "",
    showDialog: false,
    complaints: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    headers: [
      { text: "Complainant", value: "fullName" },
      { text: "NDIS number", value: "ndisNumber" },
      { text: "Date of Complaint", value: "datecreated" },
      { text: "Complaint Against", value: "complaintAgainst" },
      { text: "", value: "actions" }
    ]
  }),
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    }
  },
  methods: {
    init() {
      this.vm = {};
      const me = this;
      complaintService.list(this.currentOrganisation.id).then(result => {
        let complaints = [];
        result.docs.map(doc => {
          const complaint = doc.data();
          complaint.id = doc.id;
          if (
            complaint.createdOn != undefined &&
            typeof complaint.createdOn !== "string"
          ) {
            complaint.createdOn = complaint.createdOn.toDate();
          }
          if (
            complaint.modifiedOn != undefined &&
            typeof complaint.modifiedOn !== "string"
          ) {
            complaint.modifiedOn = complaint.modifiedOn.toDate();
          }

          complaints.push(complaint);
        });
        me.complaints = complaints;
      });
    },
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },
    addComplaint() {
      this.$store.commit("setCurrentComplaint", null);
      this.$router.push("/feedback/complaint");
    },
    editComplaint(item) {
      this.$store.commit(
        "setCurrentComplaint",
        JSON.parse(JSON.stringify(item))
      );
      this.$router.push("/feedback/complaint");
    },
    close() {
      this.$router.push("/feedback");
      setTimeout(() => {
        this.vm = {};
      }, 300);
    }
  }
};
</script>
